import './pages/sparks.js';
import { initializeWorldMap } from './pages/worldmap.js';
import { initializeDropdown } from './components/dropdown.js';
import { initializeEnergyCountdown } from './components/energy-countdown.js';
import { modalManager } from './components/modal-manager.js';
import { initializeStickySidebar } from './components/sticky-sidebar.js';

// Initialiser les composants de base
initializeWorldMap();
initializeDropdown();
initializeEnergyCountdown();
initializeStickySidebar();

// Initialiser le gestionnaire de modals
modalManager.init();