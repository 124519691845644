/**
 * Gestionnaire de modals simple avec MicroModal
 * Assure l'ouverture séquentielle des modals
 */
const modalManager = {
  // État des modals
  active: false,
  modals: {},
  closed: new Set(),
  
  /**
   * Initialise MicroModal
   */
  init() {
    // Configuration de MicroModal
    MicroModal.init({
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      disableFocus: true
    });
    
    // Exposer le gestionnaire globalement
    window.modalManager = this;
    
    // Démarrer la séquence des modals au chargement
    document.addEventListener('DOMContentLoaded', () => {
      this.showNext();
    });
  },
  
  /**
   * Enregistre une modal
   */
  registerModal(config) {
    const { id, autoOpen = true, onShow, onClose } = config;
    
    this.modals[id] = {
      id,
      open: false,
      autoOpen,
      options: {
        disableFocus: true,
        // Quand la modal s'ouvre
        onShow: () => {
          this.active = true;
          this.modals[id].open = true;
          
          if (onShow && typeof onShow === 'function') {
            onShow();
          }
        },
        // Quand la modal se ferme
        onClose: () => {
          this.modals[id].open = false;
          this.active = false;
          this.closed.add(id);
          
          if (onClose && typeof onClose === 'function') {
            onClose();
          }
          
          // Ouvrir la prochaine modal avec un délai
          setTimeout(() => this.showNext(), 300);
        }
      }
    };
  },
  
  /**
   * Affiche la prochaine modal disponible
   */
  showNext() {
    // Ne rien faire si une modal est déjà ouverte
    if (this.active) return;
    
    // Chercher la prochaine modal à afficher
    const ids = Object.keys(this.modals);
    if (!ids.length) return;
    
    // Trouver la première modal à ouvrir qui n'a pas été fermée
    const nextId = ids.find(id => 
      this.modals[id].autoOpen && !this.closed.has(id)
    );
    
    // Afficher la modal trouvée
    if (nextId) {
      const modal = this.modals[nextId];
      modal.open = true;
      this.active = true;
      MicroModal.show(nextId, modal.options);
    }
  }
};

// Exportation du gestionnaire
export { modalManager }; 