/**
 * Module pour gérer le comportement sticky de la sidebar et de la player-info
 * Assure que ces éléments restent visibles pendant le défilement de la page
 */

/**
 * Ajuste la taille maximale des éléments sticky en fonction de la hauteur de l'écran
 */
const adjustStickyElementsHeight = () => {
    const playerInfo = document.querySelector('.player-info');
    const gameSidebar = document.querySelector('.game-sidebar');
    const topMenuHeight = document.querySelector('.top-menu')?.offsetHeight || 73;
    const buffer = 27; // Espace supplémentaire pour éviter tout problème de débordement
    
    const maxHeight = window.innerHeight - topMenuHeight - buffer;
    
    if (playerInfo) {
        playerInfo.style.maxHeight = `${maxHeight}px`;
    }
    
    if (gameSidebar) {
        gameSidebar.style.maxHeight = `${maxHeight}px`;
    }
};

/**
 * Initialise le comportement sticky des sidebars
 */
export const initializeStickySidebar = () => {
    // Vérifier si on est sur une page avec .v4 (interface v4)
    if (!document.documentElement.classList.contains('v4')) {
        return;
    }
    
    // Ajuster les hauteurs au chargement
    adjustStickyElementsHeight();
    
    // Ajuster les hauteurs lors du redimensionnement de la fenêtre
    window.addEventListener('resize', () => {
        adjustStickyElementsHeight();
    });
    
    // Réajuster au chargement complet pour gérer le cas où le DOM n'était pas complètement chargé
    window.addEventListener('load', () => {
        adjustStickyElementsHeight();
    });
}; 